
import { Component, Vue, Watch } from "vue-property-decorator";
import LocationBanner from "@/components/Academy/Utility/LocationBanner.vue";
import Calendar from "@/components/Academy/Utility/Calendar.vue";

@Component({
  components: { 
    LocationBanner, 
    Calendar 
  },
})
export default class CourseInfo extends Vue {
  clicked: string | null = null;
  index = 0;
  navList = [
    { title: "選擇課程", href: "#choose-course" },
    { title: "學院據點", href: "#location" },
    { title: "班別時間", href: "#choose-class" },
    { title: "基礎電力", href: "#course1" },
    { title: "基礎水設施", href: "#course2" },
    { title: "基礎疏通工程", href: "#course3" },
    { title: "基礎工具使用", href: "#course4" },
    { title: "基礎安裝工程", href: "#course5" },
    { title: "基礎清潔", href: "#course6" },
    { title: "基礎木工", href: "#course7" },
    { title: "接案報價", href: "#course8" },
    { title: "凱撒實習", href: "#course9" },
  ];
  scrollNum = 0; //滾動距離
  isTop = false; //是否顯示上方導覽列
  getAttribute: HTMLElement;
  //hook
  mounted(): void {
    this.addScroll();
    this.addscrollChangeView();
  }
  addScroll(): void {
    window.addEventListener("scroll", this.showTop);
  }
  removeScroll(): void {
    window.removeEventListener("scroll", this.showTop);
  }
  addscrollChangeView(): void {
    window.addEventListener("scroll", this.scrollChangeView);
  }
  removescrollChangeView(): void {
    window.removeEventListener("scroll", this.scrollChangeView);
  }
  showTop(): void {
    let top =
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      window.pageYOffset;
    this.scrollNum = top;
    if (top >= 100) {
      this.isTop = true;
    } else {
      this.isTop = false;
    }
  }
  scrollChangeView(): void {
    let section = document.querySelectorAll("section");
    let navLinks = document.querySelectorAll(".href");
    section.forEach((i) => {
      let top = window.scrollY;
      let offset = i.offsetTop - 100;
      let height = i.offsetHeight;
      let id = i.getAttribute("id");
      if (top >= offset && top < offset + height) {
        navLinks.forEach((item, index) => {
          item.classList.remove("scrollactive");
          document
            .querySelector(".scroll a[value*=" + id + "]")
            ?.classList.add("scrollactive");
          if (id === "choose-course") {
            navLinks[0].scrollIntoView();
          }
          if (id === "location") {
            navLinks[1].scrollIntoView();
          }
          if (id === "choose-class") {
            navLinks[2].scrollIntoView();
          }
          if (id === "course1") {
            navLinks[3].scrollIntoView();
          }
          if (id === "course2") {
            navLinks[4].scrollIntoView();
          }
          if (id === "course3") {
            navLinks[5].scrollIntoView();
          }
          if (id === "course4") {
            navLinks[6].scrollIntoView();
          }
          if (id === "course5") {
            navLinks[7].scrollIntoView();
          }
          if (id === "course6") {
            navLinks[8].scrollIntoView();
          }
          if (id === "course7") {
            navLinks[9].scrollIntoView();
          }
          if (id === "course8") {
            navLinks[10].scrollIntoView();
          }
          if (id === "course9") {
            navLinks[11].scrollIntoView();
          }
        });
      }
    });
  }
  click(e: any): void {
    this.clicked = e.target.getAttribute("value");
    let section = document.querySelector(this.clicked as string) as HTMLElement;
    let offsetY = section.offsetTop;
    this.scrollToSection(offsetY);
  }
  scrollToSection(offsetY: number): void {
    window.scrollTo({
      top: offsetY,
      behavior: "smooth",
    });
  }
  @Watch("clicked")
  private watchclicked() {
    if (this.clicked) {
      this.removeScroll();
      this.removescrollChangeView();
      let navLinks = document.querySelectorAll(".href");
      navLinks.forEach((item) => {
        item.classList.remove("scrollactive");
      });
      let addActive = document.querySelector(".scroll a:hover") as HTMLElement;
      addActive.classList.add("clickactive");
      setTimeout(() => {
        let removeActive = document.querySelector(
          ".scroll a:hover"
        ) as HTMLElement;
        removeActive.classList.remove("clickactive");
      }, 1000);
      setTimeout(() => {
        this.clicked = null;
        this.addScroll();
        this.addscrollChangeView();
        let removeActive = document.querySelector(
          ".scroll a:hover"
        ) as HTMLElement;
        removeActive.classList.remove("clickactive");
      }, 2000);
    }
  }
}
